<template>
  <div class="homepageGW">
    <div class="banner">
      <div class="banner-left">
        <template v-if="bannerList && bannerList.length > 1">
          <el-carousel trigger="click" :autoplay="true" height="534px">
            <el-carousel-item v-for="item in bannerList" :key="item.id + 'banner'">
              <img :src="item.picUrl" @click="handleBanner(item)" class="picUrl" alt="">
            </el-carousel-item>
          </el-carousel>
        </template>
        <template v-else>
          <img :src="bannerList[0]?.picUrl" @click="handleBanner(bannerList[0])" class="picUrl" alt="">
        </template>
      </div>
    </div>
    <div class="home-contain">
      <div class="content">
        <div class="courseView-news">
          <div class="classTop classTopOne">
            <div class="classTopOne-class">
              <span>{{ classArr[0]?.ArticleName }} </span>
            </div>
            <div class="classTopCourse" v-if="cxlListOne.length">
              <div class="topItemC" v-for="itemOne in cxlListOne" :key="itemOne.id" @click="toCase(itemOne)">
                <div class="topItemC-right">
                  <div class="topGoodsName">
                    {{ itemOne.articleTitle }}
                  </div>
                  <div class="topGoodsName-second">
                    {{ itemOne.articleAbstract }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="classTop classTopOne">
            <div class="classTopOne-class">
              <span>{{ classArr[1]?.ArticleName }}</span>
            </div>
            <div class="classTopCourse" v-if="cxlListTwo.length">
              <div class="topItemC" v-for="itemOne in cxlListTwo" :key="itemOne.id" @click="toCase(itemOne)">
                <div class="topItemC-right">
                  <div class="topGoodsName">
                    {{ itemOne.articleTitle }}
                  </div>
                  <div class="topGoodsName-second">
                    {{ itemOne.articleAbstract }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="courseView courseView-goods">
          <div class="classTop">
            <div class="title">
              <span>{{ courseClassIDList.length > 0 ? courseClassIDList[0].className : '' }}</span>
              <div class="moreBtn" @click="coursPageClick">
                更多 >
              </div>
            </div>
            <div class="classTopCourse" v-if="classCourseListByIDDataOne.length">
              <div class="topItemC" v-for="itemOne in classCourseListByIDDataOne" :key="itemOne.ucode"
                @click="handleDetail(itemOne)">
                <div class="topImage">
                  <img :src=itemOne.coverImage alt="">
                </div>
                <div class="topGoodsName">
                  {{ itemOne.name }}
                </div>
                <div v-if="itemOne.studyHour" class="studyHours">
                  {{ setTimeForHoursNew(itemOne.studyHour) }}课时
                </div>
                <!-- <div v-if="itemOne.sell">
                  <div class="priceV" v-if="itemOne.goodsDto && !itemOne.goodsDto.isFree">
                    <span>
                      免费
                    </span>
                  </div>
                  <div class="priceV" v-else>
                    <span>
                      ¥{{ itemOne.goodsDto?.salePrice || 0 }}
                    </span>
                    <dd>
                      ¥{{ itemOne.goodsDto?.basePrice || 0 }}
                    </dd>
                  </div>

                </div> -->


              </div>
            </div>

          </div>

          <div class="classTop">
            <div class="title">
              <span>{{ courseClassIDList.length > 1 ? courseClassIDList[1].className : '' }}</span>
              <div class="moreBtn" @click="coursPageClick">
                更多 >
              </div>
            </div>
            <div class="classTopCourse" v-if="classCourseListByIDDataTow.length">
              <div class="topItemC" v-for="itemTow in classCourseListByIDDataTow" :key="itemTow.ucode"
                @click="handleDetail(itemTow)">
                <div class="topImage">
                  <img :src="itemTow.coverImage" alt="">
                </div>
                <div class="topGoodsName">
                  {{ itemTow.name }}
                  <!-- <dd>¥ 200</dd> -->
                  <div v-if="itemTow.studyHour" class="studyHours">
                    {{ setTimeForHoursNew(itemTow.studyHour) }}课时
                  </div>
                  <!-- <div v-if="itemTow.sell">
                    <div class="priceV" v-if="itemTow.goodsDto && !itemTow.goodsDto.isFree">
                      <span>
                        免费
                      </span>
                    </div>
                    <div class="priceV" v-else>
                      <span>
                        ¥{{ itemTow.goodsDto?.salePrice || 0 }}
                      </span>
                      <dd>
                        ¥{{ itemTow.goodsDto?.basePrice || 0 }}
                      </dd>
                    </div>

                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="courseView">
          <div class="classTop">
            <div class="classTopTitle">
              {{ courseClassIDList.length > 2 ? courseClassIDList[2].className : '' }}
            </div>
            <div class="lineView">

            </div>
            <div class="moreBtn" @click="coursPageClick">
              更多 >
            </div>
            <div class="classTopCourse" v-if="classCourseListByIDDataThree.length">
              <div class="topItemC width364" v-for="itemThree in classCourseListByIDDataThree" :key="itemThree.ucode"
                @click="handleDetail(itemThree)">
                <div class="topImage width364">
                  <img :src=itemThree.coverImage alt="">
                </div>
                <div class="topGoodsName width364">
                  {{ itemThree.name }}
                </div>
                <div v-if="itemThree.studyHour" class="studyHours">
                  {{ setTimeForHoursNew(itemThree.studyHour) }}课时
                </div>
                <!-- <div v-if="itemThree.sell">
                  <div class="priceV" v-if="itemThree.goodsDto && !itemThree.goodsDto.isFree">
                    <span>
                      免费
                    </span>
                  </div>
                  <div class="priceV" v-else>
                    <span>
                      ¥{{ itemThree.goodsDto?.salePrice || 0 }}
                    </span>
                    <dd>
                      ¥{{ itemThree.goodsDto?.basePrice || 0 }}
                    </dd>
                  </div>

                </div> -->


              </div>

            </div>

          </div>

        </div>
        <div class="zhiyejiankang">
          <div class="classTop line3num">
            <div class="classTopTitle">
              {{ courseClassIDList.length > 3 ? courseClassIDList[3].className : '' }}
            </div>
            <div class="lineView">

            </div>
            <div class="moreBtn" @click="coursPageClick">
              更多 >
            </div>
            <div class="classTopCourse flexWarp " v-if="classCourseListByIDDataFour.length">
              <div class="topItemC width364 marginBottom24" v-for="itemFour in classCourseListByIDDataFour"
                :key="itemFour.ucode" @click="handleDetail(itemFour)">
                <div class="topImage width364">
                  <img :src=itemFour.coverImage alt="">
                </div>
                <div class="topGoodsName width364">
                  {{ itemFour.name }}
                </div>
                <div v-if="itemFour.studyHour" class="studyHours">
                  {{ setTimeForHoursNew(itemFour.studyHour) }}课时
                </div>
                <!-- <div v-if="itemFour.sell">
                  <div class="priceV" v-if="itemFour.goodsDto && !itemFour.goodsDto.isFree">
                    <span>
                      免费
                    </span>
                  </div>
                  <div class="priceV" v-else>
                    <span>
                      ¥{{ itemFour.goodsDto?.salePrice || 0 }}
                    </span>
                    <dd>
                      ¥{{ itemFour.goodsDto?.basePrice || 0 }}
                    </dd>
                  </div>

                </div> -->
              </div>

            </div>

          </div>

          <div class="classTop line4num">
            <div class="classTopTitle">
              {{ courseClassIDList.length > 4 ? courseClassIDList[4].className : '' }}
            </div>
            <div class="lineView">

            </div>
            <div class="moreBtn" @click="coursPageClick">
              更多 >
            </div>
            <div class="classTopCourse flexWarp" v-if="classCourseListByIDDataFive.length">
              <div class="classMidCourse marginBottom24" v-for="itemFive in classCourseListByIDDataFive"
                @click="handleDetail(itemFive)" :key="itemFive.ucode">
                <div class="classMidCourseLeftImage">
                  <img :src=itemFive.coverImage alt="">
                </div>
                <div class="leftGoodsName">
                  <span>{{ itemFive.name }}</span>

                  <div v-if="itemFive.studyHour" class="studyHours">
                    {{ setTimeForHoursNew(itemFive.studyHour) }}课时
                  </div>
                  <!-- <div v-if="itemFive.sell">
                    <div class="priceV" v-if="itemFive.goodsDto && !itemFive.goodsDto.isFree">
                      <span>
                        免费
                      </span>
                    </div>
                    <div class="priceV" v-else>
                      <span>
                        ¥{{ itemFive.goodsDto?.salePrice || 0 }}
                      </span>
                      <dd>
                        ¥{{ itemFive.goodsDto?.basePrice || 0 }}
                      </dd>
                    </div>

                  </div> -->

                </div>
              </div>

            </div>



          </div>


        </div>



      </div>
    </div>
    <div class="kechengyoushi">
      <div class="container">
        <div class="classTopTitle">
          课程优势
        </div>
        <div class="lineView"></div>
        <p class="abstract">涵盖煤矿及非煤矿山、金属冶炼、烟花爆竹、危险化学品、建筑施工、道路交通、冶金、有色、建材、机械、轻工、纺织、商贸、烟草等行业，同时拥有人社部门工伤预防培训和技能提升主要工种。</p>
        <ul>
          <li v-for="(item, index) in dataCon?.courseA" :key="index">
            <img :src="item.src">
            <p>{{ item.text }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="classTop emerCase-main" v-if="AllCourseList.length">
      <div class="emerCase">
        <div class="classTopTitle">
          病症案例
        </div>
        <div class="lineView"></div>
        <div class="emerCase-content">
          <div class="emerCase-item" v-for="item in AllCourseList" :key="item.ucode" @click="toCase(item)">
            <div class="emerCase-box-top">
              <img :src="item.articleCover" alt="">
            </div>
            <div class="emerCase-box-bottom">
              <p class="name">{{ item.articleTitle }}</p>
              <p class="num">{{ item.articleAbstract }}</p>
            </div>
          </div>
        </div>
        <div class="moreBtn" @click="toJump({ path: '/emerCase' })">
          更多 >
        </div>
      </div>
    </div>
    <div class="anquanyignjiguanli">
      <div class="anquanyignjiguanli-container">
        <div class="classTopTitle">
          安全与应急管理培训服务
        </div>
        <div class="lineView"></div>
        <p>
          安全与应急管理培训服务平台是以人工智能、大数据、云计算、区块链、物联网等新一代先进信息技术为依托，以人才标准体系、课程标准体系、培训标准体系、评价标准体系、安全合规体系为基础，深入探究本项目相关业务领域的培训场景，采用智慧化学习方式及全流程数字化管理，为客户提供“培训+考试+证书”的一站式培训解决方案的安全与应急管理培训服务平台。
        </p>
        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/home/coo.png">
      </div>
    </div>
    <div class="anquanyignjiguanli ">
      <div class="container">
        <div class="classTopTitle">
          指导单位
        </div>
        <div class="lineView">

        </div>
        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/home/home-ft.png">
      </div>
    </div>

    <div class="anquanyignjiguanli lastone" @click="toLinkDetail">
      <div class="container bgfff">
        <div class="classTopTitle">
          友情链接
        </div>
        <div class="lineView">

        </div>
        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/shuzizaihailianjie.png">
      </div>
    </div>

    <!-- <div class="anquanyignjiguanli lastTwo">
      <div  class="container">
         <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/home/home-ft-link.png">
         <div class="btn" @click="toDetail">查看详情 <svg-icon icon-class="arrow-right-circle"></svg-icon></div>
      </div>
    </div> -->
    <div class="ai-tools" v-if="toolVisible" v-wd-drag>
      <img @click="toolVisible = false" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-h5/gpt/close.png"
        alt="" class="close" />
      <div class="ai-tools-item">
        <div class=" redGpt" @click="changeVisibleOne">
          <img :src="$route?.meta?.gpt?.imgUrl" alt="" class="aiFloating" />
          <p>AI名师</p>
        </div>
      </div>
      <div class="ai-tools-item ai-tools-item-second">
        <div class="redGpt" @click="changeVisibleTwo">
          <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/question.png" alt=""
            class="aiFloating" />
          <p>风险识别</p>
        </div>
      </div>
    </div>
    <el-drawer :modal="false" :wrapperClosable="false" class="assistant-box" :size="560"
      :visible.sync="aiVisibleChatTwo" :with-header="false">
      <ai-assistant @openAi="changeVisibleTwo" :aiVisibleChat="aiVisibleChatTwo" :gptType="3" gptText="风险识别"
        v-if="aiVisibleChatTwo"></ai-assistant>
    </el-drawer>
    <el-drawer :modal="false" :wrapperClosable="false" class="assistant-box" :size="560"
      :visible.sync="aiVisibleChatOne" :with-header="false">
      <ai-assistant @openAi="changeVisibleOne" :aiVisibleChat="aiVisibleChatOne" :gptType="$route.meta?.gpt?.gptType"
        :gptText="$route.meta?.gpt?.text" :contentId="$store.state.coures.currentlyPlay.contentId"
        v-if="aiVisibleChatOne"></ai-assistant>
    </el-drawer>
  </div>
</template>

<script>
// import GptFloating from '@/modules/gpt/components/GptFloating.vue';
import AiAssistant from '@/modules/gpt/page/index.vue'
// import wdDrag from '@/directive/drag' //拖拽
import dataCon from './../asset/js/index.js'
import { getConfigureValue } from '@/utils/configure.js'
import { branchDomain } from '@/utils/getBranchDomain'
import wdDrag from '@/directive/drag' //拖拽

export default {
  computed: {
    subUserInfo() {
      return this.$store.state.subUserInfo
    }
  },
  data() {
    return {
      toolVisible: true,
      dataCon,
      aiVisibleChatOne: false,
      aiVisibleChatTwo: false,
      classArr: getConfigureValue('homeArticlesClassIDsAndNames') && JSON.parse(getConfigureValue('homeArticlesClassIDsAndNames')),
      wdObj: getConfigureValue('yjgw_wd_base') && JSON.parse(getConfigureValue('yjgw_wd_base')),
      AllCourseList: [],
      customColors: '#AA0B0B',
      isSettingPasswordHomeDialog: false,
      dialogVisible: {
        show: false,
      },
      token: '',
      rankingTabsIndex: 0,
      bannerList: [],
      rankingTabs: [
        {
          label: 2,
          value: '学分'
        },
        {
          label: 1,
          value: '学时'
        },
        {
          label: 3,
          value: '课程'
        }
      ],
      // 学分排行
      CreditRankingList: [],
      informationList: [], // 资讯列表
      newCourseTabs: [
        {
          label: 1,
          value: '最新',
        },
        {
          label: 2,
          value: '最热',
        },
        {
          label: 3,
          value: '精品',
        }
      ],
      newCourseList: [],
      recentStudyList: [],
      studyStatistics: {},
      totalLearnTime: 0,
      courseList: [],
      courseClassIDList: [],
      className: '',
      classID: '',
      classCourseListByIDData: [],
      classCourseListByIDDataOne: [],
      classCourseListByIDDataTow: [],
      classCourseListByIDDataThree: [],
      classCourseListByIDDataFour: [],
      classCourseListByIDDataFive: [],
      pcDomain: '',
      classList: [],
      cxlListOne: [],
      cxlListTwo: [],
    }
  },
  directives: {
    wdDrag
  },
  components: {
    AiAssistant,
    // GptFloating
  },
  async created() {
    this.token = localStorage.getItem('token');
    this.getHomeData(1);
    await this.getConfiguration();
    this.classArr.forEach((item, index) => {
      if (index == 0) {
        this.getClassList(item.ArticleClassID, 5, index)
      }
      if (index == 1) {
        this.getClassList(item.ArticleClassID, 5, index)
      }
      if (index == 2) {
        this.getClassList(item.ArticleClassID, 5, index)
      }
      if (index == 3) {
        this.getClassList(item.ArticleClassID, 8, index)
      }
    })
  },
  mounted() {

  },

  methods: {
    toLinkDetail() {
      //https://www.szfzjz.cn/
      window.open(`https://www.szfzjz.cn`, '_blank');

    },
    setTimeForHoursNew(time) {
      if (time) {
        // 秒 转 分 然后45分是一课时 最后结果向上取整
        return Math.ceil(time / 60 / 45);
      }
      return 0;
    },
    getClassList(id, pageSize, index) {
      const params = {
        classId: id,
        pageNum: 1,
        pageSize: pageSize,
        sort: 1,
      };
      this.$api.ema.getArticlesByDomain(params).then((res) => {
        if (res.data) {
          if (index == 0) {
            this.cxlListOne = res.data.list;
          } else if (index == 1) {
            this.cxlListTwo = res.data.list;
          } else if (index == 2) {
            this.classList = res.data.list;
          } else if (index == 3) {
            this.AllCourseList = res.data.list;
          }
        }
      });
    },
    changeVisibleOne(data) {
      if (!localStorage.getItem('token')) {
        this.$router.push({
          path: '/login'
        })
        return
      }
      if (document.querySelector('.gptFloating') && document.querySelector('.gptFloating').getAttribute('data-drag') === 'true') {
        return
      }
      if (data === false) {
        this.aiVisibleChatOne = false;
      } else {
        this.aiVisibleChatTwo = false;
        this.aiVisibleChatOne = !this.aiVisibleChatOne;
      }
    },
    changeVisibleTwo(data) {
      if (!localStorage.getItem('token')) {
        this.$router.push({
          path: '/login'
        })
        return
      }
      if (document.querySelector('.gptFloating') && document.querySelector('.gptFloating').getAttribute('data-drag') === 'true') {
        return
      }
      if (data === false) {
        this.aiVisibleChatTwo = false;
      } else {
        this.aiVisibleChatOne = false;
        this.aiVisibleChatTwo = !this.aiVisibleChatTwo;
      }
    },
    async jump(item) {
      if (localStorage.getItem('token') && localStorage.getItem('identity') == 'user') {
        const result = await branchDomain();
        if (result?.pc) {
          const address = window.location.protocol + "//" + result?.pc;
          window.open(`${address}/course/detail?ucode=${item.ucode}`, '_self');
        } else {
          this.pcDomain = this.wdObj?.domain;
          if (this.pcDomain) {
            const address = window.location.protocol + "//" + this.pcDomain;
            window.open(`${address}/course/detail?ucode=${item.ucode}`, '_self');
          }

        }
      } else {
        this.pcDomain = this.wdObj?.domain;
        if (this.pcDomain) {
          const address = window.location.protocol + "//" + this.pcDomain;
          window.open(`${address}/course/detail?ucode=${item.ucode}`, '_self');
        }
      }



    },

    toDetail() {
      window.open(`https://65622555.wd.wdeduc.com/home`, '_blank');
    },
    handleDetail(item) {

      this.jump(item)

      // this.$router.push({
      //   path: "/course/detail",
      //   query: {
      //     ucode: item.ucode,
      //   },
      // });
    },
    toCase(item) {
      // this.$router.push({
      //       path: "/cms/detail",
      //       query: {
      //           articleId: item.id,
      //       },
      //   });
      this.$router.replace({
        path: "/cms/detail",
        query: {
          articleId: item.id,
        },
      });
    },
    async coursPageClick() {
      if (localStorage.getItem('token') && localStorage.getItem('identity')) {
        const result = await branchDomain();
        if (result?.pc) {
          const address = window.location.protocol + "//" + result?.pc;
          window.open(`${address}/courseData?token=${localStorage.getItem('token')}`, '_self');
        } else {
          this.$router.push({
            path: '/courseData'
          })
        }
      } else {
        this.$router.push({
          path: '/courseData'
        })
      }





    },
    setClassTitle(titleStr) {
      if (!titleStr) {
        return ''
      } else {
        return titleStr;
      }

    },
    async getConfiguration() {
      await this.$api.configuration.getConfiguration().then(res => {
        if (res.code === 0 && res.data) {
          let frontendConfig = res.data.frontendConfig.filter(item => {
            if (item.key === 'courseClassIDList') {
              return true;
            }
          })[0];

          if (frontendConfig && frontendConfig.value) {
            this.courseClassIDList = JSON.parse(frontendConfig.value);
            console.log(this.courseClassIDList, 'this.courseClassIDList')
            // 根据课程分类获取课程列表
            if (this.courseClassIDList.length) {
              let classNameArr = [];
              let classIDArr = [];
              this.courseClassIDList.forEach(item => {
                classNameArr.push(item.className);
                classIDArr.push(item.classID);
              });
              // type 1 三岗培训 2 注安师 3 工伤预防 4 职业健康 5 一般行业人员——公开课
              if (classIDArr[0]) {
                this.getCourseDataByClassID(classIDArr[0], 4, 1);
              }
              if (classIDArr[1]) {
                this.getCourseDataByClassID(classIDArr[1], 4, 2);
              }

              if (classIDArr[2]) {
                this.getCourseDataByClassID(classIDArr[2], 3, 3);
              }
              if (classIDArr[3]) {
                this.getCourseDataByClassID(classIDArr[3], 6, 4);
              }
              if (classIDArr[4]) {
                this.getCourseDataByClassID(classIDArr[4], 4, 5);
              }

            }
          } else {
            // this.courseClassIDList = [];
          }
        }
      });
    },

    async getCourseDataByClassID(classID, pageSize, type) {
      let base = require('js-base64').Base64;
      this.pcDomain = this.wdObj?.domain;
      console.log(this.pcDomain, 'this.pcDomain')
      let params = {
        pageNum: 1,
        pageSize: pageSize,
        domainName: base.encode(this.pcDomain)
      };
      let data = {
        categoryCode: classID,
        orderBy: 0,
        name: ''

      }
      let res = await this.$api.courseCenter.courseListByDomain({ params, data })
      if (res.data && res.data.list && res.data.list.length) {
        // this.courseList = res.data.list || [];
        if (type === 1) {
          this.classCourseListByIDDataOne = res.data.list || [];
        } else if (type === 2) {
          this.classCourseListByIDDataTow = res.data.list || [];
        } else if (type === 3) {
          this.classCourseListByIDDataThree = res.data.list || [];
        } else if (type === 4) {
          this.classCourseListByIDDataFour = res.data.list || [];
        } else if (type === 5) {
          this.classCourseListByIDDataFive = res.data.list || [];
        }
      }


    },
    findTaskList() {
      let params = {
        pageNum: 1,
        pageSize: 3,
        taskStatus: "",
        type: 1,
      };
      this.$api.learn.findTaskList({ params }).then(async (res) => {
        if (res.data && res.data.list && res.data.list.length) {
          this.courseList = res.data.list || [];
        }
      });
    },
    // 获取学习时长
    getAllLearnedData() {
      this.$api.learn.getAllLearnedData().then((res) => {
        this.totalLearnTime = res.data.totalLearnTime || 0;
      });
    },
    // 获取是否设置过密码，绑定弹窗
    async alreadySetPassword() {
      const res = await this.$api.usercenter.alreadySetPassword({});
      this.isSettingPasswordHomeDialog = res.data
      sessionStorage.setItem('isSettingPasswordHomeDialog', res.data)
      if (!this.isSettingPasswordHomeDialog) {
        this.dialogVisible.show = true;
      } else {
        this.dialogVisible.show = false;
      }

    },
    formatSeconds(value) {
      let result = parseInt(value);
      let h =
        Math.floor((result / 3600) % 24) < 10
          ? Math.floor((result / 3600) % 24)
          : Math.floor((result / 3600) % 24);

      let m =
        Math.floor((result / 60) % 60) < 10
          ? Math.floor((result / 60) % 60)
          : Math.floor((result / 60) % 60);

      let s =
        Math.floor(result % 60) < 10
          ? Math.floor(result % 60)
          : Math.floor(result % 60);

      let res = "";
      if (h !== 0) res += `${h}h`;

      if (m !== 0) res += `${m}min`;

      if (s !== 0) res += `${s}s`;

      return res;
    },
    goPersonal() {
      this.$router.push({
        path: '/personal'
      })
    },
    toJump(item) {
      this.$router.push({
        path: item.path
      })
    },
    // 课程中心详情
    jumpCourseCenterDetail(item) {
      this.$router.push({
        path: '/course/detail',
        query: {
          ucode: item.objUcode,
        }
      })
    },
    // 学习中心详情
    jumpLearnDetail(item) {
      if (item.displayStyle === 1) {
        this.$router.push({
          path: '/learn/course/detail',
          query: {
            id: item.id,
            type: item.type
          }
        })
      } else {
        this.$router.push({
          path: "/learn/StageMap",
          query: {
            id: item.id,
          },
        });
      }

    },
    // 资讯详情
    jumpInformationDetail(item) {
      this.$router.push({
        path: '/information/detail',
        query: {
          id: item.objId
        }
      })
    },
    // banner 跳转
    handleBanner(item) {
      console.log(item, 'reim=====')
      // 跳转对象类型 objType 1：文章 2：课程
      if (item.isJump) {
        if (item.objType === 1) {
          this.jumpInformationDetail(item)
        } else {
          this.jumpCourseCenterDetail(item)
        }
      }
    },
    myStudyStatistics() {
      const params = {
        hasCredit: true,//是否包括学分
        hasFinishCourseCount: true,//是否包含课程完成数量
      }
      this.$api.home.myStudyStatistics(params).then(res => {
        this.studyStatistics = res.data
      })
    },
    // 排行榜
    getUserTopCount(dataType) {
      const params = {
        pageNum: 1,
        pageSize: 10
      }
      const data = {
        dataType // 1:学时 2:学分 3:课程学习（学习完成数）
      }
      this.$api.home.getUserTopCount({ params, data }).then(res => {
        this.CreditRankingList = res.data?.dataList || []
      })
    },
    // 最近学习
    getMyTaskList() {
      const params = {
        pageNum: 1,
        pageSize: 5,
        status: 1
      }
      this.$api.learn.getMyTaskList({ params }).then(res => {
        this.recentStudyList = res.data?.list || []
      })
    },
    getHomeData(type, pageSize) {
      const params = {
        pageNum: 1,
        pageSize: pageSize || 10,
      }
      const data = {
        clientType: 1,
        dataType: type,//数据类型 1：轮番图 2：课程推荐 3：资讯
      }
      this.$api.home.findByConditionAndDomain({ data, params }).then(res => {
        if (res.data) {
          if (type === 1) {
            this.bannerList = res.data.list || [];
          } else if (type === 2) {
            this.newCourseList = res.data.list || [];
          } else if (type === 3) {
            this.informationList = res.data.list || [];
          }
        }

        console.log(res, 'res===')
      })
    },
    getTimeState() {
      // 获取当前时间
      let timeNow = new Date();
      // 获取当前小时
      let hours = timeNow.getHours();
      let minute = timeNow.getMinutes();
      // 设置默认文字
      let state = ``;
      console.log(hours, minute, 'hours====')
      // 判断当前时间段
      if (hours >= 0 && hours < 8) {
        state = `早上好!`;
      } else if (hours >= 8 && hours < 11) {
        state = `上午好!`;
      } else if (hours >= 11 && hours < 13) {
        state = `中午好!`;
      } else if (hours >= 13 && hours < 17) {
        state = `下午好!`;
      } else if (hours >= 17 && hours < 24) {
        state = `晚上好!`;
      }
      return state;
    },
    // 排行榜 tab切换
    rankingTabsClick(index, item) {
      this.rankingTabsIndex = index;
      this.getUserTopCount(item.label)
      console.log(item, 'item')
    },
  }
}
</script>


<style lang="scss" scoped src="../asset/css/home.scss"></style>
<style lang="scss" scoped src="../../gpt/assets/css/index.scss"></style>

<style lang="scss" scoped>
.bgfff {
  background: #fff;
  cursor: pointer;
}
.classTopTitle {
  width: 100%;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 28px;
  color: #CC0D0A;
  line-height: 40px;
  text-align: center;
  font-style: normal;
}

.studyHours {
  min-width: 55px;
  height: 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #6E6E6E;
  line-height: 25px;
  text-align: left;
  font-style: normal;
  margin-top: 10px;
}

.ai-tools {
  position: fixed;
  right: 80px;
  bottom: 200px;
  background: linear-gradient(180deg, #FFECEA 0%, #FFE2E2 100%);
  border-radius: 18px;
  z-index: 30;
  width: 120px;
  height: 318px;

  .ai-tools-item {
    padding: 25px 20px;
    position: relative;
    cursor: pointer;
  }

  .ai-tools-item-second {
    &::after {
      position: absolute;
      top: 0;
      height: 2px;
      background: #fff;
      content: '';
      width: 90px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .aiFloating {
    width: 75px;
    height: 75px;
  }

  .close {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 14px;
    height: 14px;
    z-index: 66;
  }

  p {
    margin-top: 5px;
    background: #1A72FF;
    padding: 5px 12px;
    border-radius: 32px;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    background: linear-gradient(180deg, #FD8E90 0%, #FD4042 100%);
  }
}

.classTopTwo-second {
  color: #353535;
  text-align: center;
  margin-bottom: 22px;
  font-size: 18px;
}

.classTopTwo {
  .classTopCourse {
    display: flex;
  }

  .classMidCourse:nth-child(2) {}

  .classMidCourseRightImage {
    display: flex;
    flex-wrap: wrap;

    img {
      width: 260px;
      height: 195px;
      margin-right: 22px;
      margin-bottom: 22px;

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(3) {
        margin-bottom: 0;
      }

      &:nth-child(4) {
        margin-bottom: 0;
      }
    }
  }

  .classMidCourse:nth-child(1) {
    width: 550px;
    height: 413px;
    margin-right: 22px;

    .classMidCourseLeftImage {
      width: 550px;
      height: 413px;
    }
  }
}

.classTopOne-class {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 18px;

  span {
    width: 268px;
    height: 45px;
    line-height: 45px;
    background: linear-gradient(180deg, #FFF3F3 0%, #FFDDDC 100%);
    border-radius: 0px 23px 23px 0px;
    color: #353535;
    text-align: center;
    font-size: 20px;
    font-weight: 500;

    &.active {
      background: linear-gradient(180deg, #FD5151 0%, #D20303 100%);
      color: #fff;
    }

    &:nth-child(1) {
      border-radius: 23px 0 0 23px;
    }
  }
}

div.classTopOne {
  .classTopCourse {
    display: block;

    .topItemC {
      width: auto;
      margin-right: 0;
      display: flex;
      margin-bottom: 20px;

      .topImage img {
        width: 264px;
        height: 199px;
        margin-right: 30px;
      }

      .topItemC-right {
        width: calc(100% - 294px);
      }

      .topGoodsName-second {
        margin-top: 10px;
        color: #6E6E6E;
      }
    }
  }
}

img {
  vertical-align: middle;
}

.lineView {
  width: 907px;
  height: 5px;
  background: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/course/lineview.png') no-repeat;
  background-size: 100% 100%;
  margin: 12px auto;
  align-self: center;
}

.anquanyignjiguanli {
  margin-top: 40px;

  .anquanyignjiguanli-container {
    background: #FFF6F6;
    padding: 40px 46px 44px;
    width: 1200px;
    box-sizing: border-box;
    margin: 0 auto 45px;

    .classTopTitle {
      width: 100%;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 28px;
      color: #CC0D0A;
      line-height: 40px;
      text-align: center;
      font-style: normal;
    }

    p {
      font-size: 16px;
      margin-bottom: 30px;
      margin-top: 24px;
    }
  }

  img {
    width: 100%;
    max-width: 1200px;
    display: block;
    margin: 0 auto;
  }
}

.lastone {
  .classTopTitle {
    width: 100%;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 28px;
    color: #CC0D0A;
    line-height: 40px;
    text-align: center;
    font-style: normal;
  }

  .container {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    box-shadow: 0px 0px 7px 0px rgba(255, 168, 168, 0.5);
    border: 1px solid #FFF6F6;
    padding: 30px 46px 40px;
    box-sizing: border-box;
  }

  .lineView {
    margin: 12px auto 42px;
  }

  background: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/home/ply-bg-new.png') no-repeat center bottom;
  background-size: 100% 374px;
  padding-bottom: 120px;
}

.lastTwo {
  padding-top: 56px;
  padding-bottom: 60px;

  .container {
    position: relative;
    width: 1200px;
    margin: 0 auto;
  }

  .btn {
    color: #fff;
    position: absolute;
    right: 96px;
    top: 141px;
    text-align: center;
    width: 150px;
    height: 40px;
    line-height: 40px;
    background: #D10000;
    border-radius: 32px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
  }
}

.kechengyoushi {
  width: 100%;
  background: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/home/rank-bg.png') no-repeat center center;
  background-size: cover;
  background-position: center;
  padding: 56px 0 68px;

  .abstract {
    font-size: 18px;
    margin: 24px 0;
  }

  .container {
    background: #fff;
    border-radius: 12px;
    width: 1200px;
    margin: auto;
    padding: 36px 40px 40px;
    box-sizing: border-box;

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        position: relative;
        width: 350px;
        height: 228px;
        margin-right: 33px;
        margin-bottom: 24px;

        &:nth-child(3n) {
          margin-right: 0;
        }

        &:nth-last-child(-n+3) {
          margin-bottom: 0;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        p {
          height: 60px;
          line-height: 60px;
          text-align: center;
          background: rgba(0, 0, 0, .44);
          position: absolute;
          bottom: 0;
          color: #fff;
          left: 0;
          right: 0;
          font-size: 18px;
        }
      }
    }

    .classTopTitle {
      width: 100%;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 28px;
      color: #CC0D0A;
      line-height: 40px;
      text-align: center;
      font-style: normal;
    }
  }
}

.home-contain {
  padding-bottom: 64px;
  background: linear-gradient(to bottom, #ffffff, #FFD6D0);
}

.classTop {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .classTopTitle {
    width: 100%;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 28px;
    color: #CC0D0A;
    line-height: 40px;
    text-align: center;
    font-style: normal;
  }

  .moreBtn {
    width: 100%;
    height: 25px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #343434;
    line-height: 25px;
    text-align: left;
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 5px;
    cursor: pointer;
  }

  .flexWarp {
    display: flex;
    flex-wrap: wrap;

  }

  .classTopCourse {
    display: flex;

    .topItemC {
      width: 265px;
      margin-right: 18px;
      cursor: pointer;

      &:nth-last-child(1) {
        margin-right: 0;
      }

      .topImage {
        img {
          width: 100%;
          height: 150px;
          object-fit: cover;
        }
      }

      .topGoodsName {
        width: 100%;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #343434;
        line-height: 25px;
        text-align: left;
        font-style: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 18px;
      }

      .priceV {
        width: 100%;
        height: 25px;
        display: flex;
        align-items: center;
        margin-top: 6px;

        span {
          height: 25px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #D10000;
          line-height: 25px;
          text-align: left;
          font-style: normal;
          margin-right: 28px;
        }

        dd {
          height: 22px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #979797;
          line-height: 22px;
          text-align: left;
          font-style: normal;
          text-decoration: line-through;


        }
      }

    }

  }

}

.line3num .classTopCourse {
  .topItemC {
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

.zhiyejiankang {
  width: 1200px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 0px rgba(239, 215, 215, 0.5);
  border-radius: 15px;
  margin-top: 47px;
  padding: 36px 40px 16px;
  box-sizing: border-box;

  .line4num {
    margin-top: 24px;
  }
}

.width364 {
  width: 360px !important;

  img {
    height: 203px !important;
    object-fit: cover;
  }
}

.margin47 {
  margin-top: 47px;

}

.marginBottom24 {
  margin-bottom: 24px;

}

.classMidCourse {
  width: 50%;
  display: flex;
  cursor: pointer;

  .classMidCourseLeftImage {
    width: 265px;
    height: 150px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }

  .leftGoodsName {
    display: flex;
    flex-direction: column;
    margin-left: 18px;
    padding-top: 20px;

    span {
      width: 238px;
      min-height: 25px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #343434;
      line-height: 27px;
      text-align: left;
      font-style: normal;
      margin-bottom: 23px;
    }

    dd {
      height: 28px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 20px;
      color: #D10000;
      line-height: 28px;
      text-align: left;
      font-style: normal;
    }


  }


}

.courseView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 1200px;
  margin-top: 40px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 0px rgba(239, 215, 215, 0.5);
  border-radius: 15px;
  padding: 36px 40px 40px;
  box-sizing: border-box;
}

.courseView-goods {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  margin-top: 40px;
  background: transparent;

  .title {
    padding-left: 16px;
    position: relative;
    font-weight: 550;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    span {
      font-size: 22px;
    }

    .moreBtn {
      width: auto;
      font-size: 14px;
      margin-bottom: 0;
    }

    &::after {
      position: absolute;
      width: 5px;
      height: 24px;
      background: linear-gradient(180deg, #FD5151 0%, #D20303 100%);
      content: '';
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .classTop {
    width: 587px;
    box-shadow: 0px 2px 10px 0px rgba(239, 215, 215, 0.5);
    background: #FFFFFF;
    padding: 24px;
    box-sizing: border-box;
    border-radius: 12px;
    justify-content: flex-start;
  }

  .classTopCourse {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .topItemC {
      width: 260px;
      margin-right: 18px;
      margin-bottom: 20px;

      img {
        width: 100%;
        height: 147px;
        object-fit: cover;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(3) {
        margin-bottom: 0;
      }

      &:nth-child(4) {
        margin-bottom: 0;
      }
    }
  }
}

.courseView-news {
  display: flex;
  background: transparent;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  margin-top: 45px;

  .classTop {
    width: 586px;
    box-sizing: border-box;
    padding: 38px 30px 40px;
    box-shadow: 0px 2px 10px 0px rgba(239, 215, 215, 0.5);
    background: #fff;
    border-radius: 12px;
    justify-content: flex-start;

    .classTopCourse .topItemC {
      margin-bottom: 15px;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }

      &:hover {
        .topGoodsName {
          color: #D10000;
        }
      }

      .topGoodsName {
        margin-top: 0;
        font-size: 18px;
        padding-left: 24px;
        position: relative;
        box-sizing: border-box;

        &::after {
          position: absolute;
          width: 10px;
          height: 10px;
          content: '';
          left: 0;
          top: 8px;
          background: linear-gradient(180deg, #FD5151 0%, #D20303 100%);
        }
      }

      .topGoodsName-second {
        padding-left: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: box;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 15px;
      }

      .topItemC-right {
        width: 100%;
      }
    }
  }

  .classTopOne-class {
    margin: 0;
    margin-bottom: 24px;

    span {
      background: linear-gradient(180deg, #FD5151 0%, #D20303 100%);
      height: 45px;
      line-height: 45px;
      color: #fff;
      border-radius: 32px;
      width: auto;
      padding: 0 32px;
    }
  }
}

.emerCase-main {
  background: #fff;
  padding: 56px 0 0;
  margin-bottom: 40px;

  .lineView {
    margin: 0 auto 35px;
  }

  div.moreBtn {
    margin-top: 32px;
    color: #343434;
    justify-content: center;
  }

  .classTopTitle {
    margin-bottom: 12px;
  }

  .emerCase {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    background: #FFF6F6;
    padding: 40px 45px;
    box-sizing: border-box;
  }

  .emerCase-content {
    display: flex;
    flex-wrap: wrap;
  }

  .emerCase-item {
    width: calc(25% - 14px);
    margin-right: 18px;
    margin-bottom: 18px;

    &:hover {
      cursor: pointer;
    }

    &:nth-child(4n) {
      margin-right: 0;
    }

    img {
      width: 100%;
      height: 198px;
      object-fit: cover;
      vertical-align: middle;
    }

    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      display: box;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 16px;
      font-weight: 500;
      color: #333;
      line-height: 22px;
      height: 44px;
      margin-top: 10px;
      text-shadow: 0px 4px 16px rgba(34, 98, 188, 0.1);
    }

    .num {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      margin-top: 10px;
      color: #353535;
    }
  }
}
</style>